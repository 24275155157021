
const DashboardTitle = (props) => {
    const {title = "", subtitle = "Mattis rhoncus urna neque viverra justo nec ultrices.", textAlign="left",
            mb=2, mt=2,  } = props;
    return (
        <div className="col-12 xl:col-12 pl-0 pr-0">
            <h2 className={`mb-${mb} mt-${mt}`} style={{textAlign}}>{title}</h2>
            <p>{subtitle}</p>
        </div>);
};
export default DashboardTitle;