import React, { useContext, useEffect, useRef, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SimulationContext } from '../../App';

const maximumClansNum = 10;

const TreeTableSimulationParameters = (props) => {
    const { simulation, tooltipParametersGlobal = [] } = useContext(SimulationContext);
    const [globalFilter1, setGlobalFilter1] = useState(null)

    useEffect(() => {
        //console.log("tooltipParametersGlobal -- ", tooltipParametersGlobal);
        if(tooltipParametersGlobal && simulation?.parameters){ generateEntries(); }
    }, [tooltipParametersGlobal, simulation])

    const generateEntries = () => {
        const {"Clan Parameters 0": clan1, "Clan Parameters 1": clan2, "Clan Parameters 2":clan3, "Clan Parameters 3": clan4,
        "STD Outbreak 0": std1, "STD Outbreak 1": std2, "STD Outbreak 2":std3, "STD Outbreak 3": std4,
        "Supernova 0": super1, "Supernova 1": super2, "Supernova 2":super3, "Supernova 3": super4,
        "Drought 0": drought1, "Drought 1": drought2, "Drought 2": drought3, "Drought 3": drought4,
        "Test Parameters": testParams, ...generalParams} = simulation?.parameters || {};

        const simulationFixed = {General: generalParams, "Test Parameters": testParams,
                                "Clan Parameters": {"Red Clan Parameters": clan1, "Green Clan Parameters": clan2, "Blue Clan Parameters":clan3, "Yellow Clan Parameters": clan4},
                                "STD Outbreaks": {"STD Outbreak 0": std1, "STD Outbreak 1": std2, "STD Outbreak 2":std3, "STD Outbreak 3": std4},
                                "Supernovas": {"Supernova 0": super1, "Supernova 1": super2, "Supernova 2":super3, "Supernova 3": super4},
                                "Droughts": {"Drought 0": drought1, "Drought 1": drought2, "Drought 2": drought3, "Drought 3": drought4}}

        if(!simulation?.totalClans?.includes("Yellow Clan")){
            delete simulationFixed["Clan Parameters"]["Yellow Clan Parameters"];
        }
        if(!simulation?.totalClans?.includes("Blue Clan")){
            delete simulationFixed["Clan Parameters"]["Blue Clan Parameters"];
        }
        if(!simulation?.totalClans?.includes("Green Clan")){
            delete simulationFixed["Clan Parameters"]["Green Clan Parameters"];
        }

        const allEntryParams = Object.entries(simulationFixed);
        //console.log("allEntryParams: ",simulationFixed);
        const formatted = formatNodesSimulationParametersRecursive(allEntryParams, [], 0, {}, Date.now());
        
        //console.log("FORMATTED: ",formatted);
        return formatted;
    }
    const formatNodesSimulationParametersRecursive = (entryParams, formattedNodes = [], key = 0, parentNode = {}, mydate) => {
        //console.log("Data: ",{ entryParams, formattedNodes });

        for (let indexLoop = 0; indexLoop < entryParams.length; indexLoop++) {
            const [paramName, paramValue] = entryParams[indexLoop];
            key++;
            const stringKey = `${key}-${paramName}`
            
            //console.log("KEY: ",key);
            //console.log("date: ",mydate);
            
            const isArray = Array.isArray(paramValue)
            if(typeof paramValue !== 'object' || isArray){
                if(!parentNode.children) formattedNodes.push({ key, data: { name: paramName, value: isArray ? "[]" : `${paramValue}` } }); 
                else parentNode.children.push({ key:stringKey, data: { name: paramName, value: isArray ? "[]" : `${paramValue}` } }); 
                //if(indexLoop === entryParams.length -1) delete parentNode.children
            }
            else {
                //console.log("CHILDREN FOUND: ", paramName)
                if(!parentNode.children) formattedNodes.push({ key, data: { name: paramName, value: "" }, children: [] }); 
                else parentNode.children.push({ key:stringKey, data: { name: paramName, value: "" }, children: [] }); //value: "<<<--------------------------------------------------------------"

                const newParent = formattedNodes[formattedNodes.length - 1];
                const newChildParent = parentNode.children ? parentNode.children[parentNode.children.length - 1] : {};

                if(!parentNode.children)formatNodesSimulationParametersRecursive(Object.entries(paramValue), formattedNodes, key, newParent, mydate);
                else formatNodesSimulationParametersRecursive(Object.entries(paramValue), formattedNodes, key, newChildParent, mydate);
                //if(indexLoop === entryParams.length -1) delete parentNode.children
                
                
            }
        }
        return formattedNodes;
    }

    const findTooltipTextNew = (name = "") => {
        let tooltip = tooltipParametersGlobal?.find(el => el[0] == name)?.[1] || name;
       return tooltip;
    }
    const getBodyName = (rowBody, col) => {
        const { name, value } = rowBody.data;

        const key = `n${col.field}${col.rowIndex}`;
        const tooltipText = findTooltipTextNew(name)
        return <div style={{cursor: value ? "pointer" : ""}} key={key} className="tooltip-simtable">
                {name}
                {value && <span className="tooltip-simtable-text">{tooltipText}</span>}
            </div>;
    }
    const getBodyValue = (rowBody, col) => {
        const { name, value } = rowBody.data;
        const key = `v${col.field}${col.rowIndex}`;
        const tooltipText = findTooltipTextNew(name)
        return <div style={{cursor:"pointer"}} key={key}  className="tooltip-simtable">
                {value}
                <span className="tooltip-simtable-text">{tooltipText}</span>
            </div>;
    }

    return (
        <div className="col-12 xl:col-12 pl-0">
            <div style={{marginTop:"0px", marginBottom:"20px",}}>
                {/* <h2 className='mb-2'>Simulation parameters</h2> */}
                <div className="col-12 mb-1">
                    <p>Note: <span className="text-secondary font-medium" style={{color: 'orange'}}>Hover</span>  over parameter label to see description</p>
                </div>

                {!simulation?.parameters && 
                    <div className='mt-1 ml-1' style={{position:"absolute", zIndex: 1, left: 0, width: "100%", height: "600px", display:"flex", justifyContent:"center", alignItems:"center",}}> 
                        <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1.5s" /> 
                    </div>}

                <TreeTable scrollable scrollHeight="600px" dataKey="id" value={generateEntries()} 
                tableStyle={{ color:"yellow", backgroundColor: "green" }} filterMatchMode='contains' /* globalFilter={globalFilter1} */>
                    <Column className='wrap-fix' key="c1" field="name" header="Parameter name" expander scrollable sortable filter body={getBodyName} filterMatchMode='contains'
                            headerTooltip='This is the name field' headerTooltipOptions={{position:"top"}}></Column>
                    <Column className='center-param-fix' key="c2" field="value" header="Value" sortable filter filterMatchMode='contains'
                            headerTooltip='This is the value field' headerTooltipOptions={{position:"top"}} body={getBodyValue} ></Column>
                </TreeTable>
            </div>
        </div>);
};
export default TreeTableSimulationParameters;