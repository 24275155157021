import { useContext } from "react";

import { Divider } from "primereact/divider";
import { SimulationContext } from "../../App";

const DividerBadge = (props) => {
    const {icon, iconSize, marginTop, marginBottom} = props;
    const { simulation } = useContext(SimulationContext);

    return (
        <Divider layout="horizontal" align="center" type="solid" style={{ marginTop,marginBottom }}>
            {icon && <i className={`pi ${icon} p-overlay-badge`} style={{ fontSize: iconSize }}></i>}
        </Divider>);
};
export default DividerBadge;