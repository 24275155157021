import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SimulationContext } from '../App';

const LegendEventCard = (props) => {
    const { allSimulations, simulation, setSimulation, loading, setLoading } = useContext(SimulationContext);
    const { title, body,colorText, footer, color, backgroundColor, hideHack, borderCssClass, icon, iconColor } = props;

    useEffect(() => {
        //console.log("LegendEventCard: ", simulation || {nope:true})
      }, [simulation]);

    if(!!simulation?.headerParams)
        return (
            <div className={`col-12 md:col-4 lg:col-4 surface-0 shadow-2 border-1 border-round border-${borderCssClass}`} 
            style={{borderWidth:"1px !important",margin:"3px",/* width:"32%", */ borderStyle:"solid !important", backgroundColor: backgroundColor }}>
                <div className="flex justify-content-between mb-3" style={{}}>
                    <div>
                        <span className="block text-500 font-medium mb-3">{title}</span>
                        <div className="text-900 font-semibold font-legend ">{body}<span className="" style={{marginLeft: 6, fontSize:15, color: color}}>({colorText})</span></div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-brown-100 border-round" style={{backgroundColor: backgroundColor, width: '2.5rem', height: '2.5rem' }}>
                        <i className={`pi ${icon} text-${iconColor}-500 text-xl`}></i>
                    </div>
                </div>

                <span className="text-500">{footer} </span>
                {!hideHack && <span className="text-500" style={{ opacity: 0 }}>{footer}</span>}{/* This line is a style hack... REFACTOR & FIX */}
            </div>
   );
};
export default LegendEventCard;