import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';
import { SelectButton } from 'primereact/selectbutton';
import { ToggleButton } from 'primereact/togglebutton';
import {withRouter } from "react-router-dom"
import { SimulationContext } from '../App';
import ApexChartLine from './adam_components/ApexChartLine';
import { editNewOptions, getIndividualGraphOptions } from '../util/ApexGraphs';
import Chart from 'react-apexcharts'

const ClanCompareGraphs = (props) => {
    const { globalOptions = {} } = props;
    const { clanCompareDisplayType = "overlayed", setClanCompareDisplayType, displaySizeGraph } = useContext(SimulationContext);

    const getGraphInfo = () => {
        if(displaySizeGraph === "small") return { height: "", cardCss: "card-small", xlCol: 4, extraCss: "pd-small" };
        else if(displaySizeGraph === "medium") return { height: "", cardCss: "card-medium", xlCol: 6, extraCss: "pd-medium" };
        else return { height: 566, cardCss: "card", xlCol: 12, extraCss: "" };
    }

    const { height, cardCss, xlCol, extraCss } = getGraphInfo();
    return(<>
            {/* <div className='col' >
                    <h3 style={{ fontWeight: 1250, }}> Clan Compare</h3>
                    <h5 className='mt-1'>Lorum ipsum dolor amit sut. Mattis rhoncus urna neque viverra justo nec ultrices.</h5>
                </div> */}

                <div id="myHeader" className='col-3 sticky sticky3 select-buttons pl-0'>
                    <ToggleButton checked={clanCompareDisplayType === "timeAligned"} onChange={(e) => setClanCompareDisplayType(e.value ? "timeAligned" : "overlayed")} 
                        className='time-aligned-toggle' tooltip='Time aligned synchronizes the graphs vertically for easier viewing'
                        onLabel="Display Normal" offLabel="Time Aligned" onIcon="pi pi-chart-line" offIcon="pi pi-clock" tooltipOptions={{ position: 'left' }}/>
                </div>

                <Divider layout="horizontal" align="center" type="solid" style={{ marginBottom: "20px", marginTop: "8px" }}></Divider>
                {clanCompareDisplayType === "timeAligned" 
                ? <div className='col lateral-clan-' style={{width: "100%"}} >
                    {props.allCharts?.map((data, i) => {
                        //console.log("CHART DATA: ", data)
                        const timeOptions = getIndividualGraphOptions(globalOptions, data, false);
                        const [newOptions={}, newSeries=[]] = editNewOptions(data, timeOptions, 0, "Red Clan", `line-1-${i}`, `Synchronized-group-${i}`);
                        const [newOptions2={}, newSeries2=[]] = data.datasets?.length > 2 
                        ? editNewOptions(data, timeOptions, 2, "Green Clan", `line-2-${i}`, `Synchronized-group-${i}`)
                        : [{},[]];
                        const [newOptions3={}, newSeries3=[]] = data.datasets?.length > 4
                        ? editNewOptions(data, timeOptions, 4, "Blue Clan", `line-3-${i}`, `Synchronized-group-${i}`)
                        : [{},[]];
                        const [newOptions4={}, newSeries4=[]] = data.datasets?.length > 6 
                        ? editNewOptions(data, timeOptions, 6, "Yellow Clan", `line-4-${i}`, `Synchronized-group-${i}`)
                        : [{},[]];

                        //if(i === 0) console.log("Simulation LATERAL: ", data);
                        return (
                        <div key={`${i}-compare`} id="synced-charts" className="col-9">
                            <h3 className='lateral-graph-title'>{data?.graphLabel?.split("Clan ")?.[1] || "No title..."}</h3>
                            <Chart type="line" height="220px" series={newSeries} options={newOptions} />
                            {newSeries2?.length > 0 && <Chart type="line" height="220px" series={newSeries2} options={newOptions2} />}
                            {newSeries3?.length > 0 && <Chart type="line" height="220px" series={newSeries3} options={newOptions3} />}
                            {newSeries4?.length > 0 && <Chart type="line" height="220px" series={newSeries4} options={newOptions4} />}
                            <Divider layout="horizontal" align="center" type="solid" style={{ marginBottom: "12px", marginTop: "44px" }}></Divider>
                        </div>);
                        })
                    }
                </div>
                : props.allCharts?.map((data, i) => {
                    return (
                        <div  key={`${i}-chartCCdiv`} className={`col-12 xl:col-${xlCol} ml-0 mr-0 pl-0 pr-0 ${extraCss}`}>
                            <div className={`${cardCss} ml-0 mr-0 pl-0 pr-0`} >
                                {/* <h5>{chartData.graphLabel}</h5> */}
                                <Chart key={`${i}-chartCC`} type="line" height={height} series={data.datasets} options={getIndividualGraphOptions(globalOptions, data, true)} />
                            </div>
                        </div>);
                })}
    </>);
};
export default withRouter(ClanCompareGraphs);