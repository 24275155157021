import React, { useContext, useEffect, useState } from 'react';
import {withRouter } from "react-router-dom"
import { SimulationContext } from '../App';

import { Divider } from 'primereact/divider';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import Chart from 'react-apexcharts'

import ApexChartLine from './adam_components/ApexChartLine';
import TreeTableSimulationParameters from './adam_components/TreeTableSimulationParameters';
import { getIndividualGraphOptions } from '../util/ApexGraphs';

import { formatSimulationDataForGraphs, getGlobalLineOptions } from '../util/ApexGraphs';
import { DeepClone, downloadSimulation, isClanCompareActive, } from '../util/helpers';
import UpperInfoCard from './UpperInfoCard';
import StickySizeOptions from '../layout/StickySizeOptions';
import DashboardTitle from './dashboard/DashboardTitle';
import SimulationComment from './dashboard/SimulationComment';
import SimulationMediaPlayer from './dashboard/SimulationPopulationMediaPlayer';
import DividerBadge from './dashboard/DividerBadge';
import GraphTabMenuSticky from './dashboard/GraphTabMenuSticky';
import LegendEventAnnotations from './dashboard/LegendEventAnnotations';
import ClanCompareGraphs from './ClanCompareGraphs';

import interactivity from '../layout/interactivity.png';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';

const DashboardMain = (props) => {
    const sizeOptionsGraphs = [{label: 'Small', value: 'small'}, {label: 'Medium', value: 'medium'}, {label: 'Large', value: 'large'}];

    const { allSimulations, setAllSimulations, simulation, setSimulation, activeTabIndex, pageYOffset, displaySizeGraph = "large", clanCompareDisplayType = "overlayed", 
    setClanCompareDisplayType, setDisplaySizeGraph} = useContext(SimulationContext);
    const [load, setLoad] = useState(false);
    const [globalLineOptions, setGlobalLineOptions] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [allSimulationsCompare, setAllSimulationsCompare] = useState([]);
    const [sim1, setSim1] = useState(null);
    const [sim2, setSim2] = useState(null);

    useEffect(() => {
        const isSimulationPath = window?.location?.pathname?.includes("/simulations/");
        //console.log("GOINGG!!!!: ", {isSimulationPath, simulation, allSimulations, willCall: (!simulation?.csv && allSimulations?.length && isSimulationPath)});

        if(!simulation?.csv && allSimulations?.length && isSimulationPath ) { //The simulation csv is reset everytime the sidemenu title is clicked "It only saves the 'simulation as an object with a title"
            handleLoadSimulation();

        }//baseline-500x500-step-8715

    }, [simulation, allSimulations]);
    useEffect(() => {
        setAllSimulationsCompare(allSimulations);
       
    }, [allSimulations]);

    const handleLoadSimulation = async(manualURL) => {
        //const timeA = Date.now();
        
        //Check if the URL matches an existing simulation from the list of titles
        const URLToUse = manualURL || window?.location?.pathname;
        const simulationUrlName = URLToUse?.split("/simulations/")?.[1] || "None Found";
        const isValidSimulation = allSimulations?.some(sim => sim?.title === simulationUrlName);

        if(!isValidSimulation) props.history.replace("/about");
        else { 
            //Now download the simulation from storage & set the context/data.
            const result = await downloadSimulation(simulationUrlName); 
                         
            //Format chart data and events for ApexCharts
            const { headerParams, timestepsArray, allEvents, totalClans, selectedLineCharts, allLineChartsDataObj }  = formatSimulationDataForGraphs(result.csv, activeTabIndex);
            const loadedSimulationData = { ...result, headerParams, allEvents, timestepsArray, totalClans, selectedLineCharts, allLineChartsDataObj };

            //Chart line options for configuration of each Apex graph
            loadedSimulationData.globalOptions = getGlobalLineOptions(timestepsArray, loadedSimulationData);
    

            const simIndex = allSimulations?.findIndex(sim => sim?.title === simulationUrlName);
            const allSimsCopy = DeepClone(allSimulations);
            allSimsCopy[simIndex].loadedData = loadedSimulationData
            //console.log("!! ALL SIMULATIONS LOADED:::: ", {simIndex, loaded: loadedSimulationData, allSims: allSimsCopy});

            if(!manualURL){ 
                setSimulation(loadedSimulationData);
                setAllSimulations(allSimsCopy);
            }

            return loadedSimulationData;
        } 
        //console.log("TIME TAKEN to load simulation::::: ", (Date.now() - timeA)/1000);
    }

    const getTotalEventsNum = () => {
        const {stds = [], droughts = [], supernovas = []} = simulation?.allEvents || {};
        const totalEvents = stds?.length + droughts.length + supernovas.length;
        return totalEvents;
    }
    const getTotalClansSurvivedToEnd = () => {
        const { datasets=[] } = simulation?.allLineChartsDataObj?.[0]?.find(el => el.graphLabel === "Population" && el.newCategory === "Overview Graph") || {};
        let totalSurvived = 0;
        datasets.forEach(dataset => {
            if(parseInt(dataset.data?.[dataset.data?.length - 1] || 0) > 0) totalSurvived++;
        })
        return totalSurvived;
    }

    const openClanCompareModal = (simData) => {
        setSim1(simulation)
        setShowModal(simData);

    }

    return (
    <div className="grid layout-main-auto-flex" >
        <GraphTabMenuSticky />

       {activeTabIndex == 0 &&
       <>
       <DashboardTitle title={"About this simulation run:"} subtitle={simulation?.title ? `Title: ${simulation?.title}` : ""}></DashboardTitle>
        
        <UpperInfoCard title={"World Size"} iconClass="pi-globe" body={`${simulation?.headerParams?.[" World Size X"]} X ${simulation?.headerParams?.["World Size Y"]}`} 
            footer={"Initial population density: "} footerHighlight={simulation?.parameters ? simulation?.parameters["Initial Population Density"] : -1} highlightCssClass="bg-blue-100" textCssClass="text-blue-500"
        />
        <UpperInfoCard title={"Total Clans"} flipHighlight={true} iconClass="pi-user" body={`${simulation?.headerParams?.["# Clans"]}`} 
            footer={" survived to the end"} footerHighlight={`${getTotalClansSurvivedToEnd()}`} highlightCssClass="bg-green-100" textCssClass="text-green-500"
        />
        <UpperInfoCard title={"Time Steps"} iconClass="pi-clock" body={`${simulation?.headerParams?.["# TimeSteps"]}`} loading={!simulation?.selectedLineCharts?.[0]?.lineOptions}
            footer={"Total events: "} footerHighlight={getTotalEventsNum()} highlightCssClass="bg-orange-100" textCssClass="text-orange-500"
        />

        {simulation?.headerParams?.simulationComment && 
            <SimulationComment title="Simulation" comment={simulation.headerParams?.simulationComment} mt={5} />}
        {simulation?.headerParams?.resultComment && 
            <SimulationComment title="Result" comment={simulation.headerParams?.resultComment} mt={1} mb={2} />}
            
        <Accordion multiple={true} activeIndex={[0,1]} className="col-12 xl:col-12 pt-3 pb-4 pl-0 pr-0 ml-0 mr-0" headerClassName='header-accordion'>
            <AccordionTab header={<h2 className='mb-2'>Population distribution over time</h2>}>
                <SimulationMediaPlayer />
            </AccordionTab>
            <AccordionTab header={<h2 className='mb-2'>Simulation Parameters</h2>} >
                <TreeTableSimulationParameters />
            </AccordionTab>
        </Accordion>
        
        <DividerBadge icon="pi-table" iconSize="2rem" marginTop="0px" marginBottom="56px"/>
        </>}
        
        <DashboardTitle title={"Graphs Of Simulation Results"} textAlign="center" subtitle={""} mt={0} mb={6} />
        
        <Accordion multiple={true} activeIndex={[]} className="col-12 xl:col-12 pt-3 pb-4 pl-0 pr-0 ml-0 mr-0" headerClassName='header-accordion'>
            <AccordionTab header={<h3 className='mb-2'>Legend</h3>}>
                <LegendEventAnnotations />
            </AccordionTab>
            <AccordionTab header={<h3 className='mb-2'>Information</h3>} >
            <DividerBadge icon="" iconSize="2rem" marginTop="2px" marginBottom="34px"/>

            <div className='col' >
                <h3 style={{ fontWeight: 1250 }}> {isClanCompareActive(activeTabIndex) ? "Clan Compare" : "Interactivity"}</h3>
                {isClanCompareActive(activeTabIndex) 
                    ? <h6 className='mt-1'>With this section you can compare the allele values for all clans at once.  View time-aligned data by toggling the button to the right.</h6>
                    : <h6 className='mt-1'>Each graph contains a number of abilities, located in the toolbar at the top right of each graph. 
                    {/* You can zoom in/out, pan, restore graph size, and toggle the menu bar for downloading */}</h6>}
                    <img src={interactivity} width="25%"></img>
                    <ul>
                        <li><strong>Zoom: </strong> Zoom in and out of the graph with the +/- buttons</li>
                        <li><strong>Selection Zoom: </strong> Click and drag inside the graphs to zoom in on a specific section of the timeline.</li>
                        <li><strong>Pan: </strong> Scrub the data left/right once zoomed in.</li>
                        <li><strong>Reset: </strong> Return the graph to its original state</li>
                        <li><strong>Export: </strong> Here you can download either a PNG of the graph, or the raw data in CSV form.</li>
                    </ul>
                    <h6 className='mt-5 mb-0 pb-0'> <strong>Tip: </strong>Hovering over most of the components on this page will give a basic tooltip explanation</h6>
            </div>
            </AccordionTab>
        </Accordion>

        {!simulation?.clanCompareActive && false &&
        <DividerBadge icon="pi-chart-bar" iconSize="2rem" marginTop="6px" marginBottom="26px"/>}

<div className="sticky sticky-transparent">
            <div id="myHeader" className='sticky2a'>
            </div>
            
            <div id="myHeader" className='sticky2b'>
                <SelectButton value={displaySizeGraph} options={sizeOptionsGraphs} onChange={(e) => setDisplaySizeGraph(e.value)}
                    tooltip='Adjust the display size of the graphs' tooltipOptions={{ position: 'left' }} />
            </div>
        </div>
        <h5 className="unsticky-text hide-filter-text mb-4">Filter graph display sizes</h5>

        {/* APEX charts */}
        {(!simulation?.clanCompareActive && simulation?.allLineChartsDataObj?.[activeTabIndex])
            ? simulation?.allLineChartsDataObj?.[activeTabIndex]?.map((data, i) => 
                <ApexChartLine key={`${i}-chart`} data={data} globalOptions={simulation?.globalOptions} normalize={activeTabIndex > 0}
                 onCompareClick={() => openClanCompareModal(data)}> </ApexChartLine>)
            : <ClanCompareGraphs key={"uni"} allCharts={simulation?.allLineChartsDataObj?.[activeTabIndex] || []} globalOptions={simulation?.globalOptions}/>
        }

        {(pageYOffset > 1350 || false) && <StickySizeOptions ></StickySizeOptions>}
    </div>);
};
export default withRouter(DashboardMain);