import React, {useContext, useEffect, useState} from 'react';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import {Link} from "react-router-dom"

import { MenuProvider } from './context/menucontext';
import { Tooltip } from 'primereact/tooltip';
import { SimulationContext } from '../App';

const AppSideMenu = (props) => {
    const { allSimulations, simulation, setSimulation, setActiveTabIndex,layoutContext  } = useContext(SimulationContext);
    const { layoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar, overlayMenuActive } = layoutContext;

    const [hovered, setHovered] = useState(null)
    
    const HomeTab = { label: 'Home', items: [{ label: 'Landing Page', icon: 'pi pi-fw pi-home', to: '/', }] };
    const SimulationTab = {
        label: 'Simulations',
        items: allSimulations
    }
    const model = [HomeTab, SimulationTab];
    
    useEffect(() => {
        //console.log("APPMENU: ", {layoutConfig, layoutState})
    },[simulation])

    const isHome = () => window?.location?.pathname?.includes("/home") || (!simulation?.title && !isSimulationCompare());
    const isSimulationCompare = () => window?.location?.pathname?.includes("/simulation-compare");
    const isSimulation = (item) => simulation?.title === item?.title && !isHome() && !isSimulationCompare();

    return (
        <MenuProvider>
            <ul className="layout-menu">

                <Tooltip target=".tooltip-item" />

                <Tooltip target=".tooltip-comments" className={`col-4 mt-1 mb-2 pl-0`}>
                    <h4 className={`col-12 pl-0`}>{hovered?.title}</h4>
                    {hovered?.simulationComment && 
                    <div className={`col-12 mt-1 mb-1 pl-0`}>
                        <h5 className="mb-1">Simulation comment</h5>
                        <span style={{whiteSpace: "pre-line"}}>{hovered.simulationComment}</span>
                    </div>}
                   {hovered?.resultComment && 
                   <div className={`col-12 mt-1 mb-1 pl-0`}>
                        <h5 className="mb-1">Results comment</h5>
                        <span style={{whiteSpace: "pre-line"}}>{hovered.resultComment}</span>
                    </div>}
                </Tooltip>

                <li key="home" className="menu-separator " style={{marginBottom: 26}}>
                        <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw pi-home ${isHome() ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                        <Link className={isHome() ? "tooltip-item layout-menuitem-active" : "tooltip-item "} data-pr-tooltip={"This will take you to the home/landing page"} data-pr-position="right"
                            data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to="/home"
                            onClick={() => {setSimulation({ }); if(layoutState?.staticMenuMobileActive){ onMenuToggle(); } }}
                            tooltip=''> Home</Link>
                        {/* <Ripple /> */}
                </li>

                <li key="SimulationCompare" className="menu-separator " style={{marginBottom: 26}}>
                        <i style={{ fontSize: "1.25rem" }} className={`layout-menuitem-icon pi pi-fw pi-chart-line ${isSimulationCompare() ? "layout-menuitem-active" : "layout-menuitem-normal"}`}></i>
                        <Link className={isSimulationCompare() ? "tooltip-item layout-menuitem-active" : "tooltip-item "} data-pr-tooltip={"This page allows you to compare the same graphs across different simulations"} data-pr-position="right"
                            data-pr-at="right+5 top" data-pr-my="left center-2"style={{ cursor: 'pointer', fontSize:"16px" }} to="/simulation-compare"
                            onClick={() => { if(layoutState?.staticMenuMobileActive){ onMenuToggle(); } }}
                            tooltip='' > Simulation Compare</Link>
                        {/* <Ripple /> */}
                </li>

                <li className="menu-separator"></li>
                    {allSimulations.map((item, i) =>
                        {return <li key={`${i}-sep`} className="menu-separator" style={{marginBottom: 12}} onMouseOver={() => setHovered(item)}>
                            <div style={{display:"flex", flexDirection:"column" }} >
                                <Link className={isSimulation(item) ? "tooltip-comments layout-menuitem-active" : "tooltip-comments "}
                                    data-pr-position="right"
                                    data-pr-at="right+5 top+5" data-pr-my="left center-2"
                                    to={item.to} onClick={() => {if(layoutState?.staticMenuMobileActive){ onMenuToggle(); } setActiveTabIndex(0); setSimulation({title: item?.title})} }>{item?.title}</Link>
                            </div>
                        {/* <Ripple /> */}
                        </li>}
                    )}

                {/* <Link href="https://blocks.primereact.org" target="_blank" style={{ cursor: 'pointer' }}>
                        <img alt="Prime Blocks" className="w-full mt-3" src={`/layout/images/banner-primeblocks${layoutConfig?.colorScheme === 'light' ? '' : '-dark'}.png`} />
                    </Link> */}
            </ul>
        </MenuProvider>);
};
export default AppSideMenu;