import React, {useRef,createContext, useContext, useEffect, useState} from 'react';
import { useEventListener, useMountEffect, useUnmountEffect } from 'primereact/hooks';
import { classNames, DomHandler } from 'primereact/utils';
import AppFooter from '../layout/AppFooter';
import AppTopbar from '../layout/AppTopbar';

import PrimeReact from 'primereact/api';
import Papa from 'papaparse';
import { getStorage, ref, listAll, getDownloadURL, } from "firebase/storage";
import {  SimulationContext } from '../App';
import StickySizeOptions from '../layout/StickySizeOptions';
import AppSideMenu from '../layout/AppSideMenu';

const MainLayout = (props) => {
    const { allSimulations, simulation, setSimulation,
        layoutContext = { layoutConfig: {}, layoutState: {}, setLayoutState: () => {}, onMenuToggle: () => {}, showProfileSidebar: () => {}, overlayMenuActive: () => {}},
     } = useContext(SimulationContext);
     const { layoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar, overlayMenuActive } = layoutContext;
    
    const topbarRef = useRef(null);
    const sidebarRef = useRef(null);    

    const blockBodyScroll = () => {
        DomHandler.addClass('blocked-scroll');
    };

    useMountEffect(() => {
        PrimeReact.ripple = true;
    })

    useEffect(() => {
        if (layoutState?.overlayMenuActive || layoutState?.staticMenuMobileActive) {
            //bindMenuOutsideClickListener();
        }
        layoutState?.staticMenuMobileActive && blockBodyScroll();
    }, [layoutState?.overlayMenuActive, layoutState?.staticMenuMobileActive]);

    const containerClass = classNames('layout-wrapper', {
        'layout-overlay': layoutConfig?.menuMode === 'overlay',
        'layout-static': layoutConfig?.menuMode === 'static',
        'layout-static-inactive': layoutState?.staticMenuDesktopInactive && layoutConfig?.menuMode === 'static',
        'layout-overlay-active': layoutState?.overlayMenuActive,
        'layout-mobile-active': layoutState?.staticMenuMobileActive,
        'p-input-filled': layoutConfig?.inputStyle === 'filled',
        'p-ripple-disabled': !layoutConfig?.ripple
    });

    return (
        <React.Fragment >
            <div className={`${layoutState?.staticMenuMobileActive ? 'layout-mobile-active' : containerClass} 
            ${window?.location?.pathname?.includes("/home") ? "background-helix" : "background-helix-graphs"}`}>
                <AppTopbar ref={topbarRef} />

                <div ref={sidebarRef} className="layout-sidebar">
                    <AppSideMenu />
                </div>
                <div className="layout-main-container">
                    <div className="layout-main">{props.children}</div>
                    <AppFooter />
                </div>
               

                <div className="layout-mask"></div>
            </div>
        </React.Fragment>);
};
export default MainLayout;