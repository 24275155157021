import { convertColorsToHex, DeepClone, numberWithCommas } from "./helpers";

////////////////// Format graphs /////////////////////
export const getTimeAlignedOptions = (globalOptions = {}, data = {}, clanCompareActive = false) => {
    const options = getIndividualGraphOptions(globalOptions, data, clanCompareActive);
    
    return options;
}
export const getMinAndMaxValues = (graphs = []) => {
    const allMins = [], allMaxes = [];
    graphs.forEach(graph => {
        const numberArray = graph.data.map(Number);
        allMins.push(Math.min(...numberArray));
        allMaxes.push(Math.max(...numberArray))
    })
    const min = Math.min(...allMins), max = Math.max(...allMaxes);
    return {min, max, allMins, allMaxes};
}
export const getIndividualGraphOptions = (globalOptions = {}, data = {}, clanCompareActive = false) => {
    //console.log("getIndividualGraphOptions DATA --- ", data);
    const options = DeepClone(globalOptions);

    //Title
    if(clanCompareActive) options.title.text = data.graphLabel?.split("Clan ")?.[1] + "" || "** Title not found** ";
    else options.title.text = data?.graphLabel || ""

    //Colors
    options.colors = clanCompareActive 
    ? convertColorsToHex(["Red","Red","Green","Green","Blue","Blue","Yellow","Yellow"]) 
    : convertColorsToHex(data?.datasets?.map(el => el.backgroundColor) || []);

    //Line Stroke
    options.stroke = {...options.stroke, colors: options.colors};
    if(clanCompareActive) options.stroke.dashArray = [0, 8, 0, 8, 0, 8, 0, 8];

    //Normalize allele graphs
    const shouldScaleNormalize = data?.newCategory === "Allele Graph";
    if(shouldScaleNormalize){ 
        options.yaxis =  { ...options.yaxis, text: "Allele value", offsetY: 2, offsetx: -5, max: 1, min: .01  }
    } 
    else {
        delete options.yaxis.max;
        delete options.yaxis.min;
    }

    //Rounding smaller values
    //if(data?.min >= -.01 && data?.max <= .01 || true){
        options.yaxis.labels.formatter = (val, opts) => formatNumsYaxis(val, opts, data.min, data.max);
    //}

    //Legend
    if(clanCompareActive){
        options.legend = {
            ...globalOptions.legend, 
            markers: {
                width: 30, height: 8, strokeWidth: 0, radius: 0, fillColors: ['','transparent','','transparent', '','transparent','','transparent'],
                customHTML: ()=> { return `<div class='horizontal-dotted-line'> <span>${" "}</span></div>`; },
            },
            itemMargin: { horizontal: 5, vertical: 0 },
            formatter: (seriesName) => { return `<span class="">${seriesName}</span>`; },
            //labels:  { colors: ["#FF00FF","#00FFFF", "#FF00FF","#00FFFF", "#FF00FF","#00FFFF", "#FF00FF","#00FFFF"] },
            //width: displaySizeGraph === "small" ? 800 : undefined, offsetY: 0 - (multiplier*1),
        }

    }
    return options;
}
export const generateAllGraphsLineOptions = (timestepsArray, simulation = {}, selectedLineCharts = [], displaySizeGraph = "large", clanCompareDisplayType="overlayed") => {
      return selectedLineCharts?.map((lineChartData) => {
        return {...lineChartData, lineOptions: getGlobalLineOptions(timestepsArray, simulation, lineChartData, displaySizeGraph, clanCompareDisplayType)};
      }) || [];
}
export const getGlobalLineOptions = (timestepsArray, simulation, lineChartData, displaySizeGraph, clanCompareDisplayType = true) => {
    const annotations = processEventAnnotations(simulation.allEvents);
    const options = {
        colors: [],
        chart: {   
            type: "line", foreColor: '#fff', background: "linear-gradient(60deg, rgba(8, 36, 75, 0.9), transparent)", 
            toolbar: {
                show: true, offsetX: 0, offsetY: 0, autoSelected: 'zoom',
                tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true, reset: true, customIcons: [] },
                export: {
                  csv: { filename: "Clan-Data", columnDelimiter: ',',  headerValue: 'value' },
                  png: { filename: "Clan-Graph", },
                  svg: { enabled: false }
                }
            },  
            zoom: {
                enabled: true, type: 'x', autoScaleYaxis: false, 
                zoomedArea: {
                    fill: { color: 'green', opacity: .4 },
                    stroke: { color: '#0D47A1', opacity: .4, width: 1 },
                }
            },
            events: {
                beforeZoom : (e, vals) => {
                        const min = vals.xaxis.min < 0 ? e.minX : vals.xaxis.min;
                        const max = vals.xaxis.min < 0 ? e.maxX : vals.xaxis.max;
                        return { xaxis: { min, max } }
                },
                mounted: (chart) => {
                    //chart.windowResizeHandler();
                }
            },
        },
        dataLabels: { enabled: false },
        title: {
            text: '', margin: 1, offsetY: 2, offsetx: -5,
            style: { fontSize: `18px`, fontWeight: 'bold' },
        },
        stroke: { colors: [], width: [2.5, 2, 2.5, 2, 2.5, 2, 2.5, 2]},
        annotations: { xaxis: annotations },
        legend: {
            show: true, showForSingleSeries: false, floating: false,  position: "top", horizontalAlign: "center", verticalAlign: "center",
            fontSize: `14px`, fontFamily: 'Helvetica, Arial', fontWeight: 100,
            markers: { 
                width: 18, height: 5, strokeWidth: 0, radius: 0, offsetY: 0,
            }, 
        },
        tooltip: {
            enabled: true,  style: { fontSize: '14px', fontFamily: 'Helvetica, Arial',  },
            x: {
                show: true,
                formatter: (seriesName) => `Timestep: ${seriesName}`,
            },
        },
        grid: {
            padding: { left: 11, right: 20 },
            row: {  colors: ['black'], opacity: .2 }, 
            column: {  colors: ['black'] },
            xaxis: { lines: { show: true } },   
            yaxis: { lines: { show: true } },  
        },
        xaxis: { //This affects the main tooltips
            tooltip: { enabled: false }, //ONLY for the smaller/simpler tooltip
            categories: timestepsArray?.map(el => parseInt(el)) || [], //Must convert to array of ints
            type: 'numeric', tickPlacement: 'between', tickAmount: 10, //convertedCatToNumeric: false,
            labels: {
                formatter: formatNumsXaxis,
                offsetX: -1,
                style: {
                    fontSize: `13px`,
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
            title: {
                text: "Timesteps", offsetX: 0,  offsetY: 0,
                style: { fontSize: `14px`, fontFamily: 'Helvetica, Arial, sans-serif',  fontWeight: 400, cssClass: 'chart-title', },
            },
        },
        yaxis: {
            tooltip: { enabled: true }, //ONLY for the smaller/simpler tooltip
            forceNiceScale: true,  tickAmount: 4,
            title: { 
                text: "", offsetX: 0, offsetY: 0, margin: 10,
                style: { fontSize: `15px`, fontFamily: 'Helvetica, Arial, sans-serif',  fontWeight: 400, margin: 12, cssClass: 'chart-title' }
            },
            labels: { 
                formatter: formatNums,
                style: { fontSize: `13px`, fontFamily: 'Helvetica, Arial, sans-serif', fontWeight: 400, margin:0, cssClass: 'apexcharts-yaxis-label' },
            },
        },
    }

    return options;
}

const processEventAnnotations = (allEvents = []) => {
  const {droughts, stds, supernovas} = allEvents;
  if(!droughts?.length) return [];
  
  const annotations = [];
  const burntRed = "#A01300", goldenrod = "#DAA520", supernovaWhite = "#FFFFFF"

  annotations.push(...droughts.map(time => ({x: time, strokeDashArray: 3, borderWidth: 3, borderColor: goldenrod })));
  annotations.push(...stds.map(time => ({x: time, strokeDashArray: 3, borderWidth: 3, borderColor: burntRed })));
  annotations.push(...supernovas.map(time => ({x: time, strokeDashArray: 3, borderWidth: 3, borderColor: supernovaWhite })));
  return annotations;
}

const formatNums = (val, opts) => { 
    const floatValue = parseFloat(val);

    if(isNaN(floatValue) || !floatValue) return "0";
    else if(floatValue < 0)  return (floatValue%1.0 === 0) ? parseInt(floatValue) : floatValue.toFixed(2);//Change this;
    else if(floatValue < 1000 && floatValue > 1) return (floatValue%1.0 === 0) ? parseInt(floatValue) : floatValue.toFixed(2);//Check if float or not
    else if(floatValue >= 1000) return numberWithCommas(val);
    else return floatValue.toFixed(2);
}
const formatNumsYaxis = (val, opts, min, max) => { 
    const floatValue = parseFloat(val);
    if(min >= -.01 && max <= .01){
        if(isNaN(floatValue) || !floatValue) return "0";
        else return floatValue.toFixed(3);
    }
    else{ return formatNums(val, opts); }
}
const formatNumsXaxis = (val, opts) => { 
    const floatValue = parseFloat(val);

    if(isNaN(floatValue) || !floatValue) return "0";
    else if(floatValue < 0) return "";
    else if(floatValue < 1000 && floatValue > 1) return parseInt(floatValue)
    else if(floatValue >= 1000) return numberWithCommas(val);
    else return floatValue.toFixed(2);
}

export const formatSimulationDataForGraphs = (data = [], activeTabIndex = 0) => {
    const timestepsArray = findRowByHeader(data, "Time Steps", true);
    const headerParams = findHeaderParams(data);

    const stds = findEventRowFromHeader(data, "STD Outbreaks");
    const droughts = findEventRowFromHeader(data, "Droughts");
    const supernovas = findEventRowFromHeader(data, "SuperNovas");
    const allEvents = {stds, droughts, supernovas};
    //console.log("formatSimulationDataForGraphs ", {timestepsArray, headerParams, ...allEvents });

    const overviewCharts = [], redClanCharts = [], blueClanCharts= [], greenClanCharts = [], yellowClanCharts = [], clansSet = new Set([]);
    const clanCharts = [{clan: "Red", chart:redClanCharts}, {clan: "Green", chart:greenClanCharts}, {clan: "Blue", chart:blueClanCharts}, {clan: "Yellow", chart:yellowClanCharts}];
    
    let backgroundColor = "gray";

    const spliced = data.slice(14);
    spliced.forEach((rowDataArray, index) => {
        const isValidDataRow = rowDataArray?.length > 0 && rowDataArray[0] !== ''; //Check for final row being empty / invalid
        const isTitle = rowDataArray?.[0]?.includes("Graph");
        
        if(isTitle && isValidDataRow){
            let { graphCategory, graphLabel, datasets, allGraphColors, allGraphLabels, newCategory } = getRowGraphInfo(rowDataArray, spliced, index + 1);
            datasets = datasets.map((data, shiftIndex) => ({ 
                graphCategory, graphLabel, newCategory, name: formatGraphLabel(allGraphLabels, shiftIndex), data, backgroundColor: allGraphColors?.[shiftIndex] || backgroundColor 
            }));

            const {min, max} = getMinAndMaxValues(datasets)
            //console.log("GRAPH TO DISPLAY --- ", {min, max, data});

            const dataToPush = { labels: timestepsArray, datasets, graphCategory, newCategory,  graphLabel, min, max};
            
            if(newCategory === "Overview Graph"){ 
                overviewCharts.push(dataToPush); 
            }
            else if(newCategory === "Allele Graph"){
                clanCharts.forEach((clanChart, indexClan) => {
                    //This function will push the data to the clan graph arrays dynamically, scaling up with newer clans.
                    setupUniqueClansArray(graphLabel, clanChart.clan, clanChart.chart, clansSet, dataToPush);
                })
            }
        }
    })//End for loop

    const clanCompare = getClanCompareGraphs({ redClanCharts, greenClanCharts, blueClanCharts, yellowClanCharts },timestepsArray);
    const allLineChartsDataObj = { 0: overviewCharts, 1: redClanCharts, 2: greenClanCharts, 3: blueClanCharts, 4: yellowClanCharts, 5: clanCompare /*All Clans*/ };
    const selectedLineCharts = allLineChartsDataObj[activeTabIndex];

    //console.log("allLineChartsDataObj -- : ", { allLineChartsDataObj, clanCompare, activeTabIndex, totalClans: [...clansSet] });
    return { headerParams, timestepsArray, allEvents, totalClans: [...clansSet], selectedLineCharts, allLineChartsDataObj};
}
//////////////////////////

const getClanCompareGraphs = (allCharts={}, labels=[]) => {
    const { redClanCharts, greenClanCharts, blueClanCharts, yellowClanCharts} = allCharts;
    const clanCompare = [];
    //console.log("CLAN COMPARE: ", greenClanCharts);
    redClanCharts.forEach((chartData, index) => {
        const allClansAlleleDataSets = [];

        allClansAlleleDataSets.push(...chartData.datasets); //Red clan
        if(greenClanCharts?.[index]) allClansAlleleDataSets.push(...greenClanCharts?.[index].datasets);
        if(blueClanCharts?.[index]) allClansAlleleDataSets.push(...blueClanCharts?.[index].datasets);
        if(yellowClanCharts?.[index]) allClansAlleleDataSets.push(...yellowClanCharts?.[index].datasets);
        
        clanCompare.push({ ...chartData, labels, datasets: allClansAlleleDataSets });
    });
    return clanCompare;
}
const setupUniqueClansArray = (graphLabel, clan = "NO Clan", clanCharts, clansSet, dataToPush) => {
    if(graphLabel?.includes(clan)){ 
        clanCharts.push(dataToPush); 
        clansSet.add(`${clan} Clan`); 
    }
}

const formatGraphLabel = (allGraphLabels = [], index = 0) => {
    let label = allGraphLabels?.[index] || "N/A";
    if(label.endsWith(":")){ label = label.substring(0, label.length - 1); } //Remove colon from the end of the labels if necessary
    return label;
}

const getRowGraphInfo = (rowHeader, data, index = -1) => {
    const [graphCategory, graphLabel, numLinesText = "numlines 0"] = rowHeader;

    const numLinesTextSplit = numLinesText.split("numlines ");
    const numRowLines = parseInt(numLinesTextSplit?.[1] || '0');
    const datasets = data.slice(index, index + numRowLines);//Slice all the data, not just the row

    const colorsStartIndex = 3;
    const colorsEndIndex = colorsStartIndex + numRowLines;
    const allGraphColors = rowHeader.slice(colorsStartIndex, colorsEndIndex);

    const labelsEndIndex = colorsEndIndex + numRowLines;
    const allGraphLabels = rowHeader.slice(colorsEndIndex, labelsEndIndex);

    const isOverviewGraph = (numRowLines === 1 || graphCategory === "Clan Graph");
    const newCategory = isOverviewGraph ? "Overview Graph" : "Allele Graph";

    const rowData = {graphCategory, graphLabel, numRowLines, datasets, allGraphColors, allGraphLabels, newCategory};
    //console.log("getRowGraphInfo rowData:::: ", rowData);
    return rowData;
}

const findEventRowFromHeader = (data = [], eventType) => {
    const foundHeaderRow = data?.findIndex(el => el[0] === "Events" && el[1] === eventType);
    if(foundHeaderRow < 0 || data?.[foundHeaderRow + 1]?.[0] == '') return [];
    else return data[foundHeaderRow + 1]
}
const findRowByHeader = (data = [], header, getDataRowBeneath = false) => {
    const foundHeaderRow = data?.findIndex(el => el[0] === header);
    if(foundHeaderRow < 0) return [];
    else return getDataRowBeneath ? data[foundHeaderRow + 1] : data[foundHeaderRow]
}
const findHeaderParams = (data) => {
    const headerDataRow = findRowByHeader(data, "Header", true);
    const headerParamTitles = findRowByHeader(data, "Header");
    const simulationComment = findRowByHeader(data, "Parameters Comment", true)?.[0] || "";
    const resultComment = findRowByHeader(data, "Result Comment", true)?.[0] || "";

    const headerParams = { simulationComment, resultComment };
    headerParamTitles.forEach((title, i) => {
        if(i > 0){ headerParams[title] =  headerDataRow[i - 1]}
    })
    return headerParams;
}

/////////////////////////////////////////////////////

export const editNewOptions = (data = { datasets: []}, globalOptions = {title: {}, chart: {}, yaxis: {}}, lateralIndex = 0, colorTitle = "Purple Clan", chartID = "", groupID) => {
    //console.log("editNewOptions DATA::: ",{data, globalOptions});
    const newSeries = [data.datasets[lateralIndex], data.datasets[lateralIndex + 1]];//The male and female datasets

    const newOptions = {
        ...globalOptions, 
        title: {...globalOptions.title, text: colorTitle} ,
        chart: {
            ...globalOptions.chart, 
            toolbar: { show: false },
            margin: 0, padding: 0, background: "linear-gradient(0turn, transparent, rgba(8, 36, 75, 0.9), transparent)", 
            //id: chartID,
            group: groupID,
        },
        yaxis: { ...globalOptions.yaxis, tooltip: { enabled: false }, }
    };

    return [newOptions, newSeries];
}
export const editNewCompareOptions = (data = { datasets: []}, globalOptions = {title: {}, chart: {}, yaxis: {}}, lateralIndex = 0, colorTitle = "Purple Clan", chartID = "", groupID) => {
    //console.log("editNewOptionsCompare DATA::: ",{data, globalOptions});
    const newSeries = [data.datasets[lateralIndex], data.datasets[lateralIndex + 1]];//The male and female datasets

    const newOptions = {
        ...globalOptions, 
        grid: {...globalOptions.grid, padding: { left: 8, right: 56 },}, //Originally left: 11, right: 20
        title: {...globalOptions.title, text: colorTitle} ,
        chart: {
            ...globalOptions.chart, 
            toolbar: { show: false },
            zoom: { enabled: false },
            /* events: {
                animationEnd: (chart) => {
                    chart.windowResizeHandler();
                  }
            }, */
            margin: 0, padding: 0, background: "linear-gradient(0turn, transparent, rgba(8, 36, 75, 0.9), transparent)", 
            id: chartID,
            group: groupID,
        },
        yaxis: { ...globalOptions.yaxis, tooltip: { enabled: false }, }
    };

    return [newOptions, newSeries];
}
////412