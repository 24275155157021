import { useContext } from "react";
import { SimulationContext } from "../../App";
import { TabMenu } from "primereact/tabmenu";
import { SelectButton } from "primereact/selectbutton";
import { ProgressSpinner } from "primereact/progressspinner";
import LegendEventCard from "../LegendEventCard";

const LegendEventAnnotations = (props) => {
    const { simulation } = useContext(SimulationContext);

    return (<>
        <h3 style={{ fontWeight:1250, fontSize: "1.6rem", marginTop:"1.25rem" }}> Note: <span style={{ fontWeight:340, fontSize:15 }}> Event annotations are represented by vertical dashed lines in each graph</span></h3>     
        <div className="legend-container" style={{borderWidth:1,borderStyle:"solid", justifyContent:"space-between", borderColor:"gray", marginBottom: "2rem", marginLeft: "0rem", marginRight:"0rem",
                    display:"flex", flexDirection:"row", width:"100%",}}>
            {!simulation?.allEvents
                ? <div className="col-12 md:col-6 lg:col-12" >
                    <div className="flex justify-content mb-3" >
                        <ProgressSpinner style={{width: '75px', height: '75px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1.5s" /> 
                    </div>
                 </div>
                : <>
                    <LegendEventCard title="When the skies hold their breath..." body="Drought" colorText="Golden" borderCssClass="drought" icon="pi-sun" iconColor="white"
                        footer="and the Earth murmers for moisture." color="goldenrod" backgroundColor="darkgoldenrod"></LegendEventCard>
                    <LegendEventCard title="Cupids communal arrow..." body="STD Outbreaks" colorText="Red" hideHack={true} borderCssClass="stds" icon="pi-users" iconColor="white"
                        footer="a viral meet-and-greet where nobody's happy about networking." color="red" backgroundColor="darkred"></LegendEventCard>
                    <LegendEventCard title="Out with a bang, not a whimper!" body="Supernovas" colorText="White" hideHack={true} borderCssClass="" icon="pi-star" iconColor="gray"
                        footer="Humanity plans, god laughs" color="" backgroundColor="white"></LegendEventCard>
                </>}
        </div></>);
};
export default LegendEventAnnotations;

//Old Supernova text: nature's new software update may add fun new features and bewildering bugs.