import React, { useContext } from 'react';
import { SimulationContext } from '../App';

const AppFooter = () => {
    const { allSimulations, simulation, setSimulation, 
        layoutContext = { layoutConfig: {}, layoutState: {}, setLayoutState: () => {}, onMenuToggle: () => {}, showProfileSidebar: () => {}, overlayMenuActive: () => {}},
     } = useContext(SimulationContext);
     const { layoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar, overlayMenuActive } = layoutContext;

    return (
        <div className="layout-footer" style={{display:"flex", flexDirection:"column"}}>
            {/* <img src={`/layout/images/logo-${layoutConfig?.colorScheme === 'light' ? 'dark' : 'white'}.svg`} alt="Logo" height="20" className="mr-2" /> */}
            <h6 className='mt-2 mb-2'>Copyright 2023 Moral Dimension <span className="font-bold">LLC</span>, all rights reserved.</h6>
            <p className='mt-2 mb-0 '>"MORALDIMENSION" and "MORAL DIMENSION" are <strong>registered trademarks</strong> of Moral Dimension LLC</p>
        </div>
    );
};

export default AppFooter;
