import { useContext } from "react";
import { SimulationContext } from "../../App";
import { TabMenu } from "primereact/tabmenu";
import { SelectButton } from "primereact/selectbutton";
import { Tooltip } from "primereact/tooltip";

const GraphTabMenuSticky = (props) => {
    const { simulation, activeTabIndex = 0, setActiveTabIndex, displaySizeGraph = "large", setDisplaySizeGraph } = useContext(SimulationContext);

    const sizeOptionsGraphs = [{label: 'Small', value: 'small'}, {label: 'Medium', value: 'medium'}, {label: 'Large', value: 'large'}];

    const setTabs = () => {
        const enabledTabs = [{ label: "Overview", value: "Overview" }, { label: "Red Clan Genome", value: "Red Clan" }, 
            { label: "Green Clan Genome", value: "Green Clan" }, { label: "Blue Clan Genome", value: "Blue Clan" }, { label: "Yellow Clan Genome", value: "Yellow Clan" }, 
            { label: "All Clans", value: "All Clans" }]
        enabledTabs.forEach((tab, i) => {
            if(i > 0 && !simulation?.totalClans?.includes(tab.value) && tab.value !== "All Clans"){ tab.disabled = true; }
        })
        return enabledTabs;
    }

    return (<>
                <Tooltip target=".tabmenu-text" mouseTrack mouseTrackLeft={10} content="Select a tab to view different datasets" />
                
                <div id="myHeader" className="tab-menu sticky ui-toolbar ui-widget ui-widget-header ui-corner-all ui-shadow ui-sticky pl-0">
                    <div className="layout-main ui-toolbar ui-widget ui-widget-header ui-corner-all ui-shadow ui-sticky">
                        <TabMenu scrollable className="tabmenu-text" model={setTabs()} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)} />  
                    </div>
                </div>

{/*                 <div className="sticky sticky-transparent">
                    <div id="myHeader" className='sticky2a'>
                    </div>
                    
                    <div id="myHeader" className='sticky2b'>
                        <SelectButton value={displaySizeGraph} options={sizeOptionsGraphs} onChange={(e) => setDisplaySizeGraph(e.value)}
                            tooltip='Adjust the display size of the graphs' tooltipOptions={{ position: 'left' }} />
                    </div>
                </div>

                <p className="unsticky-text hide-filter-text">Filter graph display sizes</p> */}
            </>);
};
export default GraphTabMenuSticky;