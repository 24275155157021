import React, { useContext, useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts'
import { SimulationContext } from '../../App';
import { getIndividualGraphOptions } from '../../util/ApexGraphs';
import { Button } from 'primereact/button';

const ApexChartLine = (props) => {
    const { data = { datasets: [] },  globalOptions = {}, normalize = false } = props;
    const { displaySizeGraph } = useContext(SimulationContext);

    const getGraphInfo = () => {
        if(displaySizeGraph === "small") return { height: "", cardCss: "card-small", xlCol: 4, extraCss: "pd-small" };
        else if(displaySizeGraph === "medium") return { height: "", cardCss: "card-medium", xlCol: 6, extraCss: "pd-medium" };
        else return { height: 566, cardCss: "card", xlCol: 12, extraCss: "" };
    }

    const { height, cardCss, xlCol, extraCss } = getGraphInfo();
    return (
        <div className={`col-12 xl:col-${xlCol} ml-0 mr-0 pl-0 pr-0 ${extraCss}`}>
            <div className={`${cardCss} ml-0 mr-0 pl-0 pr-0`} >
                {/* <h5>{chartData.graphLabel}</h5> */}
                
                {//Formerly button used to open up simulation compare modal
                /* <Button type="button" icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text p-button-plain" onClick={props.onCompareClick} /> */}
                <Chart type="line" height={height} series={data.datasets} options={getIndividualGraphOptions(globalOptions, data, false)} />
            </div>
        </div>);
};
export default ApexChartLine;