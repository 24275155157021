import { useContext } from "react";

import { Image } from "primereact/image";
import { ProgressSpinner } from "primereact/progressspinner";
import { SimulationContext } from "../../App";
import { Card } from "primereact/card";

const SimulationMediaPlayer = (props) => {
    const {comment = "N/A"} = props;
    const { simulation } = useContext(SimulationContext);

    return (
        <Card style={{backgroundColor: "rgba(75, 75, 75, .2)", width: "fit-content"}} >
            <div className='simulation-player' style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor: "rgba(75, 75, 75, .05)"}}> 
            {simulation?.gifUrl || simulation?.imageUrl// || true
                ? <Image preview className='mt-1 ' src={simulation?.gifUrl || simulation?.imageUrl} alt="Image" width="390"/>
                : <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1.5s" />} 
            </div>
            <p className="mt-2">Clan population distribution over time </p>
        </Card>);
};
export default SimulationMediaPlayer;