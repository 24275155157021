import React, { useContext, useEffect, useRef, useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SimulationContext } from '../App';

const UpperInfoCard = (props) => {
    const { allSimulations, simulation, setSimulation, loading, setLoading } = useContext(SimulationContext);

    const centerItemStyle = { width: '2.5rem', height: '2.5rem' };
    const { title, body, footer, footerHighlight, highlightCssClass, textCssClass, iconClass, flipHighlight = false } = props;

    useEffect(() => {
        //console.log("YIPPEEE: ", simulation || {nope:true})
      }, [simulation]);

    if(true || !!simulation?.headerParams)
        return (
                <div className="col-12 md:col-6 lg:col-4  pl-0 pr-2 " /* style={{marginLeft:"1rem"}} */> 
                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                        {(!simulation?.headerParams)
                        ? <div className="flex justify-content mb-3" >
                            <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        </div>
                        :<><div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-500 font-medium mb-3">{title}</span>
                                <div className="text-900 font-medium text-xl">{body}</div>
                            </div>
                            <div className={`flex align-items-center justify-content-center ${highlightCssClass} border-round`} style={centerItemStyle}>
                                <i className={`pi ${iconClass} ${textCssClass} text-xl`}></i>
                            </div>
                         </div>
                        {flipHighlight
                            ?   <>
                                    <span className="text-green-500 font-medium">{footerHighlight} </span>
                                    <span className="text-500">{footer}</span>
                                </>
                            :   <>
                                    <span className="text-500">{footer}</span>
                                    <span className="text-green-500 font-medium">{footerHighlight} </span>
                                </>
                        }
                        </> }
                        
                    </div>
                </div>
        );
    else return <ProgressSpinner style={{width: '75px', height: '75px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
};
export default UpperInfoCard;