import PrimeReact from 'primereact/api';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useState } from 'react';
import { SimulationContext } from '../App';

const StickySizeOptions = (props) => {
    const { allSimulations, simulation, setSimulation, displaySizeGraph="large", setDisplaySizeGraph=()=>{},
        layoutContext = { layoutConfig: {}, layoutState: {}, setLayoutState: () => {}, onMenuToggle: () => {}, showProfileSidebar: () => {}, overlayMenuActive: () => {}},
     } = useContext(SimulationContext);
     const { layoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar, overlayMenuActive } = layoutContext;

    useEffect(() => {
        applyScale();
    }, [displaySizeGraph]);

    const applyScale = () => {
        document.getElementById(displaySizeGraph).style.fontSize = layoutConfig?.scale + 'px';
        document.getElementById(displaySizeGraph).style.color = "lightgray";
    };

    const onSizeButtonClick = (size) => { 
        setDisplaySizeGraph(size)
    };

    return (
        <>
            <button id="small" className="layout-config-button p-link" type="button" onClick={()=>onSizeButtonClick("small")}>
                <i className="pi pi-stop-circle dot-small"></i>
            </button>
            <button id="medium" className="layout-config-button config-button2 p-link " type="button" onClick={()=>onSizeButtonClick("medium")}>
                <i className="pi pi-stop-circle dot-medium"></i>
            </button>
            <button id="large" className="layout-config-button config-button3 p-link " type="button" onClick={()=>onSizeButtonClick("large")}>
                <i className="pi pi-stop-circle dot-large"></i>
            </button>
        </>);
};
export default StickySizeOptions;