//import Link from 'next/link';
import { classNames } from 'primereact/utils';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { SimulationContext } from '../App';
import { logout } from '../util/helpers';
import banner from "./moral-dimensions-banner1.png"

const AppTopbar = forwardRef((props, ref) => {
    const { allSimulations, simulation, setSimulation, layoutContext } = useContext(SimulationContext);
     const { layoutConfig, layoutState, setLayoutState, onMenuToggle, showProfileSidebar, overlayMenuActive } = layoutContext;

    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));

    const toggleSideMenuFunc = () => {
        //console.log("MENU: ", layoutContext)
        onMenuToggle();
    }

    const getTitle = () => {
        if(isHome()) return "Moral Dimension";
        else if (isSimulationCompare()) return "Simulation Compare";
        else if(simulation?.title) return `Simulation Results: ${simulation.title}`
        else return "";
    }

    const isHome = () => window?.location?.pathname?.includes("/home") || (!simulation?.title && !isSimulationCompare());
    const isSimulationCompare = () => window?.location?.pathname?.includes("/simulation-compare");
    const isSimulation = (item) => simulation?.title === item?.title || (!isHome() && !isSimulationCompare());
    const getMobileLayoutFix = () => layoutState?.staticMenuMobileActive ? "top-fix-menu-open" : "top-fix"
    return (
        <div className="layout-topbar">

        {/*  <img src={banner} style={{width:"360px", height:"56px"}}></img> */}
            <div className={layoutState?.staticMenuMobileActive ? 'topbar-width-shift-open' : 'topbar-width-shift'}>
                <button  ref={menubuttonRef} type="button" className={`p-link layout-menu-button layout-topbar-button `} onClick={toggleSideMenuFunc}>
                    <i className="pi pi-bars" />
                </button>
            </div>
            <div style={{ width:"80%", display:"flex", justifyContent:"flex-end"}}>
                <h4 style={{ textAlign: "center", width: "100%"}}>{getTitle()}</h4>
            </div>

{/*
            <button  ref={menubuttonRef} type="button"
                className={`p-link layout-menu-button layout-topbar-button  ${isSimulation()  ? getMobileLayoutFix() : "" }`} onClick={toggleSideMenuFunc}>
                <i className="pi pi-bars" />
            </button>
            <h4 style={{ width:"100%", textAlign: "center"}}>{getTitle()}</h4>

             <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutContext?.profileSidebarVisible })}>
                <button type="button" className="p-link layout-topbar-button"  id="Logout" onClick={logout}>
                    <i className="pi pi-sign-out" id="Signout"title="Signout" ></i>
                    <span>Signout</span>
                </button>
            </div> */}

        </div>
    );
});
export default AppTopbar;