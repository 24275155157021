import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCyqI3DtYpkW1hv6-m0F0v-9NKcdvF2IHk",
    authDomain: "moral-dimensions.firebaseapp.com",
    databaseURL: "https://moral-dimensions.firebaseio.com",
    projectId: "moral-dimensions",
    storageBucket: "moral-dimensions.appspot.com",
    appId: "1:1096273662853:web:f3fbb2ffc4e847e6baaaec",
    //measurementId: "G-HS45LKYTN0"
};

const initFireApp = () => initializeApp(firebaseConfig);
export const db = getFirestore(initFireApp())
export default initFireApp;