import React, { useContext, useEffect, useState } from 'react';
import {withRouter } from "react-router-dom"
import { SimulationContext } from '../App';
import { Divider } from 'primereact/divider';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import Chart from 'react-apexcharts'

import { editNewCompareOptions, getIndividualGraphOptions } from '../util/ApexGraphs';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockUI } from 'primereact/blockui';

import { formatSimulationDataForGraphs, getGlobalLineOptions } from '../util/ApexGraphs';
import { DeepClone, downloadSimulation, } from '../util/helpers';
import DashboardTitle from './dashboard/DashboardTitle';

//Only show syncronized graphs
//Select 2 simulations with dropdown at the top; this will show all graphs compared among those 2 simulations

const SimulationCompare = (props) => {
    const { allSimulations, setAllSimulations, simulation, activeTabIndex,  sim1, setSim1, sim2, setSim2} = useContext(SimulationContext);

    const [loading, setLoading] = useState(false);//Make local to avoid re-rendering from the context

    useEffect(() => {}, [simulation, allSimulations]);

    const handleLoadSimulation = async(manualURL) => {
        setLoading(true);
        let loadedSimulationData = {};
        //console.log("ALL SIMULATIONS: ", allSimulations);
        //const timeA = Date.now();
        
        //Check if the URL matches an existing simulation from the list of titles
        const simulationUrlName = manualURL?.split("/simulations/")?.[1] || "None Found";
        const isValidSimulation = allSimulations?.some(sim => sim?.title === simulationUrlName);

        const foundData = allSimulations?.find(simulation => simulation.title === simulationUrlName && simulation?.loadedData?.title && false) || false;

        if(!isValidSimulation) {
            setLoading(false);
            //console.log("1 NOT LOADING")
            props.history.replace("/home");
            return;
        }
        else if(foundData){ loadedSimulationData = foundData; }//Graph is already loaded
        else { 
            //Now download the simulation from storage & set the context/data.
            const result = await downloadSimulation(simulationUrlName); 
                         
            //Format chart data and events for ApexCharts
            const { headerParams, timestepsArray, allEvents, totalClans, selectedLineCharts, allLineChartsDataObj }  = formatSimulationDataForGraphs(result.csv, activeTabIndex);
            loadedSimulationData = { ...result, headerParams, allEvents, timestepsArray, totalClans, selectedLineCharts, allLineChartsDataObj };

            //Chart line options for configuration of each Apex graph
            loadedSimulationData.globalOptions = getGlobalLineOptions(timestepsArray, loadedSimulationData);
    

            const simIndex = allSimulations?.findIndex(sim => sim?.title === simulationUrlName);
            const allSimulationsCopy = DeepClone(allSimulations);
            allSimulationsCopy[simIndex].loadedData = loadedSimulationData
            //console.log("!! handleLoadSimulation -- LOADED:::: ", {simIndex, newlyLoaded: loadedSimulationData, allSimulations: allSimulationsCopy});
            setLoading(false);

            //setAllSimulations(allSimulationsCopy);
        } 
        setLoading(false);

        //console.log("TIME TAKEN to load simulation::::: ", (Date.now() - timeA)/1000);
        return loadedSimulationData;
    }

    const setSim1Value = async(event) => {
        const { value } = event;
        const loadedSim = await handleLoadSimulation(value)
        //console.log("Call setSim1Value: ", {value, old: simulation, new: loadedSim})

        setSim1(loadedSim);
    }
    const setSim2Value = async(event) => {
        const { value } = event;

        const loadedSim = await handleLoadSimulation(value)
        //console.log("Call setSim2Value: ", {old: simulation, new: loadedSim})
        setSim2(loadedSim);
    }

    const interpolateSimulationData = (timestepsSmaller = [], timestepsLarger = [], dataValuesLarger = [] ) => {
        //Test case for dataset 2 having greater
       /*   
            timestepsSmaller = ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60'];
            dataValuesSmaller =   [12,   6,    -8,  119,   10,   38,   -40,  -44,  -36,  -34,  -24,  25,   118 ];

            timestepsLarger = ['0', '7', '14', '21', '28', '35', '42', '49', '56', '63', '70', '77', '84', '91', '98', '105', '112'];
            dataValuesLarger =   [ 1,   22,   58,    6,   66,   78,  -11,  -14,  -10,  136,   -9,  -15,   24,  135,  155,   150,  -19]; 
        */

        const timestepDiff = parseInt(timestepsLarger[1]);//Difference between timestamps in the second dataset

        //Loop through the first set of timestamps, to generate the new interpolated values from the second set
        const dataValuesInterpolated = timestepsSmaller.map((timestamp, i) => {
            if(i === 0) return dataValuesLarger[0]; //Always return the first value in the dataset
            else{
                //Find failure cases / test for them
                let findOtherIndexGreater = 1; //Find the FIRST index of array 2 that is greater than the current index of array 1 
                let notDone = true;
                timestepsLarger.forEach((time2, ind2) => {
                    if(parseInt(time2) > parseInt(timestamp) && notDone){//Open on top set interpolation type
                        findOtherIndexGreater = ind2;
                        notDone = false;
                    }
                })

                //The upper limit of the timestep interval that we need to interpolate
                const timestepArray2 = timestepsLarger[findOtherIndexGreater];

                //The 2 values that our new interpolated value will be sandwhiched in between
                const currVal = dataValuesLarger[findOtherIndexGreater];
                const prevVal = dataValuesLarger[findOtherIndexGreater - 1];//Look into aserts
                const currentIntervalValueChange = (currVal - prevVal)/timestepDiff; //Delta interval change per single timestep
                //-7.43
                //CurrValue = 6
                //Target timestep = 20

                const stepsToJump = parseInt(timestepArray2) - parseInt(timestamp);
                const finalValue = currVal - (currentIntervalValueChange * stepsToJump);

                //console.log(currentIntervalValueChange + " --- " + stepsToJump + " --- final: " + finalValue + " --- ind --- " +findOtherIndexGreater+ " --- currvalue --- ", currVal)

                return finalValue;
            }
        })

        return dataValuesInterpolated;
    }

    const getSimSyncOptions = (graphCategoryIndex, graphIndex, simIndex, graphData, simTitle = "Title", globalOptions = {}, shouldShortenTimesteps = false, timestepsSmaller = [], timestepsCurrent = []) => {
        if(!graphData || !Object.keys(graphData)?.length) return {options: {}, data: []};
        //console.log(` -------  `, graphData);
        const TICK_AMOUNT = 10;


        //console.log("NEW VALUES: ",{graphData, shouldShortenTimesteps , timestepsSmaller , timestepsCurrent})
        if(!shouldShortenTimesteps){
            //graphData.datasets[0] = {...graphData.datasets[0], data: graphData?.datasets || []}
            globalOptions.xaxis.categories =  timestepsCurrent?.map(str => parseInt(str));
            graphData.labels = timestepsCurrent;

            const timeOptions = getIndividualGraphOptions(globalOptions, graphData, false);
            //console.log(`2 GRAPH DATA: ${simTitle} - `, {timeOptions, graphData})
            
            const [options={}, data=[]] = editNewCompareOptions(graphData, DeepClone(timeOptions), simIndex, simTitle, `line-1-${graphCategoryIndex}-${graphIndex}`, `Synchronized-group-${graphCategoryIndex}-${graphIndex}`)
            //console.log(`3 GRAPH DATA: ${simTitle} - `, {options, graphData, data})
            options.xaxis.tickAmount = TICK_AMOUNT;
            return {options, datasets: graphData?.datasets || []}
        }
        else{//This means the graph is being truncated
            let dataValuesLarger = [], timestepsLarger = timestepsCurrent;
            graphData.datasets.forEach((dataset, i) => {
                dataValuesLarger = dataset?.data || [];
                const dataValuesInterpolated = interpolateSimulationData(timestepsSmaller, timestepsLarger, dataValuesLarger);
    
                graphData.datasets[i] = {...dataset, data: dataValuesInterpolated}
            })


            globalOptions.xaxis.categories = timestepsSmaller?.map(str => parseInt(str));
            graphData.labels = timestepsLarger;
            
            const timeOptions = getIndividualGraphOptions(globalOptions, graphData, false);
            //console.log(`2 SHORTENED TIME DATA: ${simTitle} - `, {timeOptions, graphData, timestepsSmaller})
            simTitle += " \n[x-axis truncated and interpolated, detailed structure may be lost.]"
            const [options={}, data=[]] = editNewCompareOptions(graphData, DeepClone(timeOptions), simIndex, simTitle, `line-1-${graphCategoryIndex}-${graphIndex}`, `Synchronized-group-${graphCategoryIndex}-${graphIndex}`)
            //console.log(`3 GRAPH DATA: ${simTitle} - `, {options, graphData, data})
            options.xaxis.tickAmount = TICK_AMOUNT;
            //graphData.datasets[0].data.splice(6);
            //console.log(`2 SHORTENED TIME DATA: ${simTitle} - `, {graphData, timestepsSmaller,  globalOptions,})
            return {options, datasets: graphData?.datasets || []}
        }


    }
 
    const [overviewIndex, redClanIndex, greenClanIndex, blueClanIndex, yellowClanIndex, allClansIndex] = [0, 1, 2, 3, 4, 5];
    const [sim1Index, sim2Index] = [0, 2]
    
    return (<BlockUI blocked={loading} fullScreen>

                {loading && <ProgressSpinner style={{width: '150px', height: '150px', display:"flex", position: "absolute", top: "50%", left: "50%", zIndex: 999}} strokeWidth="5"  />}
                
                <div className="grid">
                    <DashboardTitle title={"Simulation Compare"} subtitle={"Use this portal to compare the graphs of any 2 simulations in our system"}></DashboardTitle>
                        
                        <div className="grid col-12">
                            <div className="col-12 md:col-6 lg:col-6 ">
                                <Dropdown style={{ }} value={sim1?.to} onChange={setSim1Value} options={allSimulations || []}  optionValue='to' optionLabel='title'
                                placeholder="Select a Simulation" className="w-full " checkmark="true"  highlightonselect="false" />
                                    
                                {sim1?.headerParams?.simulationComment && 
                                    <div className={`col-12 mt-1 mb-1 pl-0`}>
                                        <h5 className="mb-1">Simulation comment</h5>
                                        <span style={{whiteSpace: "pre-line"}}>{sim1?.headerParams?.simulationComment}</span>
                                    </div>}
                                {sim1?.headerParams?.resultComment && 
                                    <div className={`col-12 mt-1 mb-1 pl-0`}>
                                        <h5 className="mb-1">Results comment</h5>
                                        <span style={{whiteSpace: "pre-line"}}>{sim1?.headerParams?.resultComment}</span>
                                    </div>}
                            </div>

                                <div className="col-12 md:col-6 lg:col-6 ">
                                    <Dropdown style={{ }} value={sim2?.to} onChange={setSim2Value} options={allSimulations || []}  optionValue='to' optionLabel='title'
                                    placeholder="Select a Simulation" className="w-full" checkmark="true"  highlightonselect="false" />
                                    
                                    {sim2?.headerParams?.simulationComment && 
                                        <div className={`col-12 mt-1 mb-1 pl-0`}>
                                            <h5 className="mb-1">Simulation comment</h5>
                                            <span style={{whiteSpace: "pre-line"}}>{sim2?.headerParams?.simulationComment}</span>
                                        </div>}
                                    {sim2?.headerParams?.resultComment && 
                                        <div className={`col-12 mt-1 mb-1 pl-0`}>
                                            <h5 className="mb-1">Results comment</h5>
                                            <span style={{whiteSpace: "pre-line"}}>{sim2?.headerParams?.resultComment}</span>
                                        </div>}
                                </div>
                        </div>
            
                        <div className="grid col-12"></div>
            
                        <Accordion multiple={true} activeIndex={[0]} className="col-12 xl:col-12 pt-3 pb-4 pl-0 pr-0 ml-0 mr-0" >
                            <AccordionTab header={<h2 className='mb-2'>Overview</h2>}>
                                <div className="grid col-12">
                                    <div className="col-12 md:col-12 lg:col-12 ">
                                        {!loading && sim2 && sim1?.allLineChartsDataObj?.[overviewIndex]?.map((graphDataSim1, graphIndex) => {
                                            const graphDataSim2 = sim2?.allLineChartsDataObj?.[overviewIndex]?.[graphIndex];
                                            //const sameTimesteps = JSON.stringify(sim1.timestepsArray) === JSON.stringify(sim2.timestepsArray);

                                            let shouldShortenTimesteps = parseInt(sim1?.timestepsArray?.[ sim1?.timestepsArray?.length - 1]) > parseInt(sim2?.timestepsArray?.[ sim2?.timestepsArray?.length - 1]);
                                            const sim1Graph = getSimSyncOptions(overviewIndex, graphIndex, sim1Index, graphDataSim1, sim1.title, sim1.globalOptions, shouldShortenTimesteps, sim2?.timestepsArray, sim1?.timestepsArray);
                                        
                                            shouldShortenTimesteps = parseInt(sim2?.timestepsArray?.[sim2?.timestepsArray?.length - 1]) > parseInt(sim1?.timestepsArray?.[ sim1?.timestepsArray?.length - 1]);
                                            const sim2Graph = graphDataSim2 
                                            ? getSimSyncOptions(overviewIndex, graphIndex, sim2Index, graphDataSim2, sim2.title, sim2.globalOptions, shouldShortenTimesteps, sim1?.timestepsArray, sim2?.timestepsArray)
                                            : {};

                                            return <div id="synced-charts" key={`charts-${graphIndex}`}>
                                                        <h3 className='lateral-graph-title'>{graphDataSim1?.graphLabel || "No Title"} </h3>
                                                        
                                                        <Chart type="line" height="250px" series={sim1Graph?.datasets || []} options={sim1Graph?.options} />
                                                        {sim2Graph?.datasets?.length > 0 &&
                                                            <Chart type="line" height="250px" series={sim2Graph?.datasets} options={sim2Graph?.options} />}
            
                                                        <Divider />
                                                    </div>
                                            //else return null
                                        })}
                                    </div>
                                </div>
                            </AccordionTab>
                            
            {[redClanIndex, greenClanIndex, yellowClanIndex, blueClanIndex].map((clanIndex, i) => {
                const headerText = ['Red Clan', 'Green Clan', 'Yellow Clan', 'Blue Clan']?.[i] || "Other"

                return  <AccordionTab key={`color-clan-${i}`} header={<h2 className='mb-2'>{headerText}</h2>} >
                    <div className="grid col-12">
                        <div className="col-12 md:col-12 lg:col-12 ">
                            {!loading && sim2 && sim1?.allLineChartsDataObj?.[clanIndex]?.length && sim2?.allLineChartsDataObj?.[clanIndex]?.length
                            ? sim1?.allLineChartsDataObj?.[clanIndex]?.map((graphDataSim1, graphIndex) => {
                                const graphDataSim2 = sim2?.allLineChartsDataObj?.[clanIndex]?.[graphIndex];
                                
                                let shouldShortenTimesteps = parseInt(sim1?.timestepsArray?.[ sim1?.timestepsArray?.length - 1]) > parseInt(sim2?.timestepsArray?.[ sim2?.timestepsArray?.length - 1]);
                                const sim1Graph = getSimSyncOptions(clanIndex, graphIndex, sim1Index, graphDataSim1, sim1.title, sim1.globalOptions, shouldShortenTimesteps, sim2?.timestepsArray, sim1?.timestepsArray);
                            
                                shouldShortenTimesteps = parseInt(sim2?.timestepsArray?.[sim2?.timestepsArray?.length - 1]) > parseInt(sim1?.timestepsArray?.[ sim1?.timestepsArray?.length - 1]);
                                const sim2Graph = graphDataSim2 
                                ? getSimSyncOptions(clanIndex, graphIndex, sim2Index, graphDataSim2, sim2.title, sim2.globalOptions, shouldShortenTimesteps, sim1?.timestepsArray, sim2?.timestepsArray)
                                : {};

                                
                                return <div id="synced-charts" key={`charts-${graphIndex}`}>
                                            <h3 className='lateral-graph-title'>{graphDataSim1?.graphLabel || "No Title"}</h3>
                                            
                                            <Chart type="line" height="250px" series={sim1Graph?.datasets || []} options={sim1Graph?.options} />
                                            {sim2Graph?.datasets?.length > 0 &&
                                                <Chart type="line" height="250px" series={sim2Graph?.datasets} options={sim2Graph?.options} />}

                                            <Divider />
                                        </div>
                                //else return null
                            })
                            : <h4>{(sim1 && sim2) ? 'Both graphs must have the corresponding clan data in order to compare' : ""}</h4>
                        }
                        </div>
                    </div>
                </AccordionTab>})
            }
                            

                        </Accordion>
                            
                        <Divider />
                </div>

    </BlockUI>)
};
export default withRouter(SimulationCompare);